import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';

//----------------------

//export const MAIN_API_URL = 'http://localhost/std-mayo/api';
export const MAIN_API_URL = 'https://www.aiforabetterworld.es/ws';

//----------------------


export const swalError = (message) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'error',
		title: 'Ups!',
		confirmButtonText: 'Aceptar',
		text: message,
	});
};

export const swalOk = (title, message) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'success',
		title: title,
		confirmButtonText: 'Aceptar',
		text: message,
	});
};

export const swalInfo = (title, message) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'info',
		title: title,
		confirmButtonText: 'Aceptar',
		text: message,
	});
};

export const swalOkAction = (title, message, action, cancel=false) => {
	const MySwal = withReactContent(Swal);
	MySwal.fire({
		icon: 'success',
		title: title,
		confirmButtonText: 'Aceptar',
		showCancelButton: cancel,
		cancelButtonText: 'Cancelar',
		text: message,
	}).then((result) => {
		if (result.isConfirmed) {
			action();
		}
	});
};


export const formatDate = (str) => {
	return moment(str).format('\\E\\l DD-MM [a las] HH:mm');
};

export const formatDateNormal = (str) => {
	return moment(str).format('DD/MM/YYYY - HH:mm');
};
