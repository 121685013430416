import React, { useState, useEffect } from 'react';

//Enrutado
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga';

//cookies
import CookieConsent from 'react-cookie-consent';

//Loader
import LoadingLayer from './loaders/LoadingLayer';

const Layout = ({ component: Component, noCont, isAdmin, ...props }) => {
	const [isLoading, setIsLoading] = useState(false);

	ReactGA.initialize('G-5HJNGJZQMS');

	useEffect(() => {
		props.path && ReactGA.pageview(props.path);
	}, [props]);

	return isLoading ? (
		<LoadingLayer visible={isLoading} hideAll />
	) : (
		<div className="main-layout">
			<CookieConsent
				location="bottom"
				buttonText="Aceptar"
				style={{ background: '#fff', color: '#000' }}
				buttonStyle={{ color: '#fff', background: '#000', fontSize: '12px' }}
				expires={150}
			>
				Utilizamos cookies de terceros para analizar y mejorar tu navegación. Si
				continuas navegando, consideramos que aceptas su uso. Puedes cambiar la
				configuración u obtener más información en nuestra política de cookies.
			</CookieConsent>

			<Route render={(properties) => <Component {...props} />} />
		</div>
	);
};

export default Layout;
