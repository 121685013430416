import React from 'react';

const LoadingLayer = ({ visible, hideAll = false }) => {
	return (
		visible && (
			<div className={`overlay upper white ${hideAll ? 'full' : ''}`}>
				<div className="spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		)
	);
};

export default LoadingLayer;
