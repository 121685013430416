import React, { useState, useRef } from 'react';

//bs
import {
	Container,
	Row,
	Col,
	FormGroup,
	FormFeedback,
	Input,
	Label,
	Button,
} from 'reactstrap';

//form
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

//comps
import LoadingLayer from '../components/loaders/LoadingLayer';
import { swalError } from '../utils/helpers';
import * as Responsive from '../components/Responsive';

//service
import * as mainApi from '../api/mainApi';

//images
import ImageTitForm from '../css/images/formulario.png';
import Enabling from '../css/images/enabling-people.png';
import InnoCampus from '../css/images/innovation-campus.png';
import SMSLogo from '../css/images/logo-samsung.png';

import ImageGracias from '../css/images/gracias.png';
import ImageAgenda from '../css/images/agenda.png';
import ImagenAgendaBtn from '../css/images/agenda-btn.png';

import ImageGraciasMB from '../css/images/graciasmobile.png';
import ImageAgendaMB from '../css/images/agendamobile.png';
import IcoLink from '../css/images/ico-link.png';

const HomePage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const formikRef = useRef();

	const emptyForm = {
		nombre: '',
		apellidos: '',
		email: '',
		telef: '',
	};

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required('El nombre es obligatorio'),
		apellidos: Yup.string().required('Los apellidos son obligatorios'),
		email: Yup.string()
			.email('Ingresa un formato de correo válido')
			.required('El email es obligatorio'),
		telef: Yup.string()
			.required('El teléfono es obligatorio')
			.max(9, 'El teléfono debe tener 9 dígitos'),
		entidad: Yup.string().required('La entidad es obligatoria'),
	});

	const gotoUpper = () => {
		if ('scrollBehavior' in document.documentElement.style) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			window.scrollTo(0, 0);
		}
	};

	const handleSubmitForm = async (formValues) => {
		setIsLoading(true);
		const data = await mainApi.sendInscription(formValues);
		if (data && data.succeded) {
			setIsVisible(!isVisible);
			gotoUpper();
			formikRef.current.resetForm(emptyForm);
		} else {
			swalError(data.message);
		}
		setIsLoading(false);
	};

	return (
		<div className={`main-container ${!isVisible ? '' : 'thanks'}`}>
			<div className="home-sbs">
				<LoadingLayer visible={isLoading} />

				<Responsive.Desktop>
					<div className="floating-std">
						<img
							src={InnoCampus}
							alt="Innovation campus summit"
							className="img-fluid"
						/>
						<img src={SMSLogo} alt="Samsung" className="img-logo" />
					</div>
				</Responsive.Desktop>

				<div id="inscription">
					<div className="cont-form">
						<Container className="text-center cont-home">
							<div className={`slide-form ${!isVisible ? '' : 'sended'}`}>
								<Responsive.Mobile>
									<div className="ball"></div>
									<div className="mobile-logo">
										<img src={SMSLogo} alt="Samsung" className="img-fluid" />
									</div>
								</Responsive.Mobile>
								<Responsive.Desktop>
									<div className="claim">
										Regístrate para asistir al
										<br />I Innovation Campus Summit
									</div>
								</Responsive.Desktop>

								<div className="big-tit mt-4">
									<img
										src={ImageTitForm}
										alt="Formmulario"
										className="img-fluid"
									/>
								</div>
								<Responsive.Mobile>
									<div className="claim mobile">
										Registrate para asistir al I Innovation Campus Summit
									</div>

									<img
										src={InnoCampus}
										alt="Innovation campus summit"
										className="img-fluid"
									/>
								</Responsive.Mobile>

								<Row className="deco-form">
									<Col md="12" className="mt-5">
										<div className="main-form">
											<Formik
												innerRef={formikRef}
												enableReinitialize
												initialValues={emptyForm}
												validateOnBlur={false}
												validateOnChange={false}
												validationSchema={validationSchema}
												onSubmit={handleSubmitForm}
											>
												{({ isSubmitting, errors }) => {
													return (
														<Form autoComplete="off">
															<div className="fields">
																<FormGroup className="mb-4">
																	<Label for="nombre">Nombre</Label>
																	<Input
																		tag={Field}
																		name="nombre"
																		id="nombre"
																		type="text"
																		placeholder="Nombre"
																		autoComplete="off"
																		className={`${
																			errors.nombre ? 'has-error' : ''
																		}`}
																	/>
																	{errors.nombre && (
																		<FormFeedback>{errors.nombre}</FormFeedback>
																	)}
																</FormGroup>

																<FormGroup className="mb-4">
																	<Label for="apellidos">Apellidos</Label>
																	<Input
																		tag={Field}
																		name="apellidos"
																		id="apellidos"
																		type="text"
																		placeholder="Apellidos"
																		autoComplete="off"
																		className={`${
																			errors.apellidos ? 'has-error' : ''
																		}`}
																	/>
																	{errors.apellidos && (
																		<FormFeedback>
																			{errors.apellidos}
																		</FormFeedback>
																	)}
																</FormGroup>

																<FormGroup className="mb-4">
																	<Label for="email">Email</Label>
																	<Input
																		tag={Field}
																		name="email"
																		id="email"
																		type="email"
																		placeholder="Email"
																		autoComplete="off"
																		className={`${
																			errors.email ? 'has-error' : ''
																		}`}
																	/>
																	{errors.email && (
																		<FormFeedback>{errors.email}</FormFeedback>
																	)}
																</FormGroup>

																<FormGroup className="mb-4">
																	<Label for="telef">Teléfono</Label>
																	<Input
																		tag={Field}
																		name="telef"
																		id="telef"
																		type="text"
																		placeholder="Teléfono"
																		autoComplete="off"
																		className={`${
																			errors.telef ? 'has-error' : ''
																		}`}
																	/>
																	{errors.telef && (
																		<FormFeedback>{errors.telef}</FormFeedback>
																	)}
																</FormGroup>

																<FormGroup className="mb-4">
																	<Label for="entidad">Entidad</Label>
																	<Input
																		tag={Field}
																		name="entidad"
																		id="entidad"
																		type="text"
																		placeholder="Entidad"
																		autoComplete="off"
																		className={`${
																			errors.entidad ? 'has-error' : ''
																		}`}
																	/>
																	{errors.entidad && (
																		<FormFeedback>
																			{errors.entidad}
																		</FormFeedback>
																	)}
																</FormGroup>

																<Row>
																	<Col md="12" className="mb-4 mt-2">
																		<p className="info-site">
																			<span>
																				19 de Septiembre de 2024 de 10:00h a
																				13:30h
																			</span>
																			<span>Auditorio El Beatriz Madrid</span>
																		</p>
																	</Col>
																</Row>

																<Row>
																	<Col
																		md="12"
																		className="d-flex justify-content-center mb-4"
																	>
																		<a
																			href="https://www.aiforabetterworld.es/agenda.pdf"
																			target="_blank"
																			rel="noreferrer"
																			title="Descarga la agenda"
																		>
																			<img
																				src={ImagenAgendaBtn}
																				alt="Descarga la agenda"
																				className="img-fluid"
																			/>
																		</a>
																	</Col>
																	<Col md="12" className="legal-fm">
																		<div className="legal-disc">
																			<img src={IcoLink} alt="legalicon" />
																			<p>
																				Sus datos serán tratados por Samsung
																				Electronics Iberia, S.A.U. para tramitar
																				su inscripción en el evento y pedirle
																				opinión sobre el mismo. Puede ejercer
																				sus derechos y obtener más informacion{' '}
																				<a
																					href="https://www.aiforabetterworld.es/SamsungSummitAI2024.pdf"
																					rel="noreferrer"
																					target="_blank"
																				>
																					aquí.
																				</a>
																			</p>
																		</div>
																	</Col>
																</Row>

																<Button
																	type="submit"
																	color="send"
																	className="mb-2"
																	disabled={isSubmitting}
																>
																	Enviar
																	<div className="buble"></div>
																</Button>
															</div>
														</Form>
													);
												}}
											</Formik>
										</div>
									</Col>
								</Row>
							</div>

							<div className={`result ${!isVisible ? '' : 'visible'}`}>
								<Row className="thx-form">
									<Col md="12">
										<Responsive.Mobile>
											<div className="ball"></div>
										</Responsive.Mobile>
										<Responsive.Desktop>
											<img
												src={ImageGracias}
												alt="Gracias por registrarte"
												className="img-fluid my-5"
											/>
											<a
												href="https://www.aiforabetterworld.es/agenda.pdf"
												target="_blank"
												rel="noreferrer"
												title="Descarga la agenda"
											>
												<img
													src={ImageAgenda}
													alt="Descarga la agenda"
													className="img-fluid mt-5"
												/>
											</a>
										</Responsive.Desktop>
										<Responsive.Mobile>
											<img
												src={ImageGraciasMB}
												alt="Gracias por registrarte"
												className="img-fluid my-5"
											/>
											<a
												href="https://www.aiforabetterworld.es/agenda.pdf"
												target="_blank"
												rel="noreferrer"
												title="Descarga la agenda"
											>
												<img
													src={ImageAgendaMB}
													alt="Descarga la agenda"
													className="img-fluid mt-5 mb-5"
												/>
											</a>
										</Responsive.Mobile>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
				</div>
				<div className="floating-end">
					<img src={Enabling} alt="Enabling people" className="img-fluid" />
				</div>
			</div>
		</div>
	);
};

export default HomePage;
