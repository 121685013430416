import React from 'react'

//main css
import './css/app.scss';

//routing
import { BrowserRouter as Router, Switch} from 'react-router-dom';

//main
import Layout from './components/Layout';

//paginas
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';


const App = () => {

  return (
    <Router>
      <Switch>
        <Layout exact path="/" component={HomePage} />
        <Layout component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

export default App